import '../vendor';
import '../main.js';
import App from './App';
import Vue from 'vue';
import i18n from '../publisher/i18n';
import vueJsModal from 'vue-js-modal/dist/ssr.nocss';

Vue.use(vueJsModal, { dialog: true });

Vue.component('e-form-group', require('@/publisher/views/v3/components/gComponents/e-form-group.vue').default);
Vue.component('e-input', require('@/publisher/views/v3/components/gComponents/e-input.vue').default);
Vue.component('e-modal', require('@/publisher/views/v3/components/gComponents/e-modal.vue').default);
Vue.component('e-dropdown', require('@/publisher/views/v3/components/gComponents/e-dropdown.vue').default);

/* eslint-disable no-new */
new Vue({
  el: '#app',
  i18n,
  components: { App },
  template: '<App/>',
});
