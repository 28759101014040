import Vue from 'vue';
import VueNotify from './vue2-notify';

Vue.use(VueNotify, {
  position: 'top-right',
  enter: 'fadeIn',
  leave: 'fadeOut',
  visibility: 5000,
  closeButtonClass: 'close',
  itemClass: 'alert alert-contrast alert-dismissible',
  mode: 'html',
  //permanent: true // NOTE: comment to hide after 3s
});

const types = {
  info: { itemClass: 'alert-info', iconClass: 'notify-icon s7-info' },
  error: { itemClass: 'alert-danger', iconClass: 'notify-icon s7-close' },
  warning: {
    itemClass: 'alert-warning',
    iconClass: 'notify-icon s7-attention',
  },
  success: { itemClass: 'alert-success', iconClass: 'notify-icon s7-check' },
};

Vue.$notify.setTypes(types);
