<template>
  <nav class="navbar">
    <div class="flex-v__center">
      <div
        class="menu-burger display-none-desktop"
        :class="{ active: isMobileNavBar }"
        @click="isMobileNavBar = !isMobileNavBar"
      >
        <div class="menu-burger_1"></div>
        <div class="menu-burger_2"></div>
        <div class="menu-burger_3"></div>
      </div>

      <a href="/" class="logo">
        <span class="logo-icon">
          <svg width="77" height="77" viewBox="0 0 77 77" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M53.5157 46.436L67.7444 73.6779H51.6193L37.2681 46.436H53.5157Z" fill="#00D89A" />
            <path
              d="M62.0167 31.0776L76.3892 57.9968L67.8519 73.6766L56.0897 51.6339L53.3994 46.6067L62.0167 31.0776Z"
              fill="#20F7C9"
            />
            <path d="M53.7583 46.8988H37.2681L45.5532 31.0776H62.0435L53.7583 46.8988Z" fill="#04BAA0" />
            <path d="M44.7886 0L15.2852 60.9762L23.429 77L52.7816 16.6504L44.7886 0Z" fill="#4DD5FF" />
            <path d="M29.1642 0.00195312L0 60.7449L15.5597 60.8274L44.7899 0.00195312H29.1642Z" fill="#3BA2FF" />
            <path d="M7.8085 76.9986H23.4342L15.6272 60.7393L0.00390625 60.7416L7.8085 76.9986Z" fill="#2773FF" />
          </svg>
        </span>
        <span class="logo-text">Andromo</span>
      </a>
    </div>

    <div class="navbar-box" :class="{ 'shrjak': !isMobileNavBar }">
      <ul class="main-menu">
        <li class="display-none-mobile">
          <button type="button" class="main-menu-link purple">
            {{ $t('navbar.easyStart') }}
          </button>
        </li>
        <li>
          <a href="/#/projects/" class="main-menu-link">
            {{ $t('navbar.projects') }}
          </a>
        </li>
        <!-- <li>
          <a href="/#/changelog/" class="main-menu-link">
            {{ $t('navbar.whatsNew') }}
          </a>
        </li> -->
        <language-selector @showHide="isMobileNavBar = !isMobileNavBar" />
      </ul>
    </div>

    <transition name="fade" mode="out-in">
      <div v-if="isShowBtn" class="btn-big btn-success" @click="$emit('copy')">
        {{ $t('easyStart.btn.create-an-app') }}
      </div>
    </transition>
  </nav>
</template>

<script>
import languageSelector from '@/publisher/components/languageSelector';

export default {
  name: 'menuNavBar',

  components: {
    languageSelector,
  },

  props: ['isShowBtn'],

  data: () => ({
    isMobileNavBar: false,
  }),
};
</script>
