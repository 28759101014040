<template>
  <li class="list-dropdown lang-menu">
    <template v-if="isDesctop">
      <e-dropdown position="bottom-end" :space="0" @on-close="closeLangDropdown()">
        <button id="languageSelectorDropdown" class="lang-menu-selected dropdown main-menu-link" type="button">
          {{ currentLanguage }}
        </button>

        <template #content="{ closeDropdown }">
          <div class="list-dropdown-box list-lang">
            <template v-for="(lang, code) in languages">
              <button
                v-if="$i18n.locale != code"
                :id="'lang_' + lang.name"
                :key="`Lang_${code}`"
                type="button"
                class="list-dropdown-item"
                @click="closeLangDropdown(), closeDropdown(), changeLanguage(code)"
              >
                {{ lang.nativeName }}
              </button>
            </template>
          </div>
        </template>
      </e-dropdown>
    </template>
    <template v-else>
      <button class="lang-menu-selected dropdown main-menu-link" type="button" @click="closeLangDropdown()">
        {{ currentLanguage }}
      </button>
      <transition name="fade" appear>
        <div v-if="isShowLangMenu" class="list-lang">
          <template v-for="(lang, code) in languages">
            <button
              v-if="$i18n.locale != code"
              :id="'lang_' + lang.name"
              :key="`Lang_${code}`"
              type="button"
              class="list-dropdown-item main-menu-link"
              @click="$emit('showHide'), changeLanguage(code)"
            >
              {{ lang.nativeName }}
            </button>
          </template>
        </div>
      </transition>
    </template>
  </li>
</template>

<script>
const LOCAL_STORAGE_LANG_KEY_NAME = 'app-lang';
import languages from '../../../../common/languages';

export default {
  data: () => ({
    languages,
    isDesctop: true,
    isShowLangMenu: false,
  }),

  computed: {
    currentLanguage() {
      return this.languages[this.$i18n.locale]?.nativeName || 'language';
    },
  },

  watch: {
    '$i18n.locale': function (val) {

      try {
        window.localStorage.setItem(LOCAL_STORAGE_LANG_KEY_NAME, val);
      } catch (e) {
        console.log('Lang change not supported!');
      } 

    },
  },

  created() {
    let lang = 'en';

    try {
      lang = window.localStorage.getItem(LOCAL_STORAGE_LANG_KEY_NAME);
    } catch (e) {
      console.log('Lang change not supported!');
    } 

    const langUrl = this.checkLangUrl();

    if (langUrl) {
      lang = langUrl;
    }

    if (lang) {
      this.$i18n.locale = lang;
      document.documentElement.lang = lang;
      return;
    }

    if (!lang && window.navigator.languages?.length) {
      window.navigator.languages.some((language) => {
        if (Object.keys(this.languages).includes(language)) {
          this.$i18n.locale = language;
          return true;
        }
        return false;
      });
    }

    try {
      window.localStorage.setItem(LOCAL_STORAGE_LANG_KEY_NAME, this.$i18n.locale);
    } catch (e) {
      console.log('Lang change not supported!');
    }
    
  },

  mounted() {
    window.addEventListener('resize', this.resizeWidth);
    this.resizeWidth();
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.resizeWidth);
  },

  methods: {
    checkLangUrl() {
      const langUrlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(langUrlSearchParams.entries());
      return params.lang;
    },

    changeLanguage(code) {
      this.$i18n.locale = code;
      document.documentElement.lang = code;

      __TE({
        category: 'User',
        action: 'Language changed ' + code,
      });

      window.location.reload();
    },

    closeLangDropdown() {
      this.isShowLangMenu = !this.isShowLangMenu;
    },

    resizeWidth() {
      this.clientWidth = document.body.clientWidth;
      this.isDesctop = this.clientWidth >= 991;
    },
  },
};
</script>
