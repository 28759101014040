<template>
  <div :class="'notify-' + options.position" :style="{ width: width }" class="notify">
    <transition-group name="notify" tag="div" @enter="slideDown" @leave="slideUp">
      <div v-for="(item, key) in items" :key="`key_${key}`" class="notify-item">
        <div :class="item.options.itemClass">
          <button
            v-if="item.options.closeButtonClass"
            :class="item.options.closeButtonClass"
            type="button"
            aria-label="Close"
            @click="removeItem(key)"
          >
            <span aria-hidden="true">&times;</span>
          </button>

          <span v-if="item.options.iconClass" :class="item.options.iconClass" />

          <div v-if="item.options.mode === 'html'" v-html="item.text" />
          <template v-else>{{ item.text }}</template>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import Velocity from 'velocity-animate';
import Vue from 'vue';

export default {
  data() {
    return {
      types: {
        info: { itemClass: 'alert-info', iconClass: 'fa fa-lg fa-info-circle' },
        error: {
          itemClass: 'alert-danger',
          iconClass: 'fa fa-lg fa-exclamation-triangle',
        },
        warning: {
          itemClass: 'alert-warning',
          iconClass: 'fa fa-lg fa-exclamation-circle',
        },
        success: {
          itemClass: 'alert-success',
          iconClass: 'fa fa-lg fa-check-circle',
        },
      },
      options: {
        itemClass: 'alert col-12',
        duration: 500,
        visibility: 2000,
        position: 'top-left',
        enter: 'slideDown',
        leave: 'slideUp',
        closeButtonClass: false,
        width: '300px',
        mode: 'text',
        permanent: false,
      },
      items: {},
      idx: 0,
    };
  },
  computed: {
    width() {
      if (this.options.position === 'top-full' || this.options.position === 'bottom-full') {
        return 'auto';
      }
      return this.options.width;
    },
  },
  methods: {
    setTypes(types) {
      this.types = types;
    },
    addItem(type, msg, options) {
      const defaultOptions = {
        iconClass: this.types[type].iconClass,
        itemClass: [this.options.itemClass, this.types[type].itemClass],
        visibility: this.options.visibility,
        mode: this.options.mode,
        closeButtonClass: this.options.closeButtonClass,
        permanent: this.options.permanent,
      };
      const itemOptions = Object.assign({}, defaultOptions, options);

      // get idx
      const idx = this.idx;

      // check if this message is already shown
      for (const key in this.items) {
        /* istanbul ignore else */
        if (Object.prototype.hasOwnProperty.call(this.items, 'key')) {
          if (this.items[key].text === msg) {
            return;
          }
        }
      }

      // add it to the queue (if it's not already there)
      Vue.set(this.items, this.idx, { type, text: msg, options: itemOptions });

      // increment key
      this.idx++;

      // remove item if not permanent
      if (itemOptions.permanent === false) {
        // remove item from array
        setTimeout(() => {
          this.removeItem(idx);
        }, this.options.duration + itemOptions.visibility);
      }
    },
    slideDown(el) {
      Velocity(el, this.options.enter, { duration: this.options.duration });
    },
    slideUp(el, done) {
      Velocity(el, this.options.leave, {
        duration: this.options.duration,
        complete: done,
      });
    },
    removeItem(index) {
      Vue.delete(this.items, index);
    },
    removeAll() {
      this.items = {};
    },
  },
};
</script>
