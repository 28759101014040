'use strict';

require('./config.tmp');

try {
  // eslint-disable-next-line global-require
  require('./config');
} catch (error) {
  // do nothing
}
