<template>
  <div class="form-group" :class="getGroupClasses">
    <slot v-if="label || getSlots('label')" name="label">
      <label>
        {{ label }}
        <span v-if="required" class="text-error">*</span>
      </label>
    </slot>

    <slot></slot>

    <small v-if="error" class="error">{{ error }}</small>

    <slot v-if="hint || getSlots('hint')" name="hint">
      <div class="hint">{{ hint }}</div>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'EFormGroup',

  props: {
    label: {
      type: [Number, String],
      default: '',
    },

    error: {
      type: [String, Boolean],
      default: '',
    },

    hint: {
      type: [Number, String, Boolean],
      default: false,
    },

    required: {
      type: [Number, Boolean],
      default: false,
    },
  },

  computed: {
    getGroupClasses() {
      return {
        'is-invalid': this.error,
      };
    },
  },

  methods: {
    getSlots(name) {
      if (this.$slots[name]) {
        return this.$slots[name];
      }

      if (this.$scopedSlots[name]) {
        return this.$scopedSlots[name];
      }
      return false;
    },
  },
};
</script>
