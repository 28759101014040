<template>
  <input
    :id="`el_${_uid}`"
    :disabled="disabled"
    :name="name"
    :title="title"
    :type="type"
    :value="value"
    :placeholder="placeholder"
    autocomplete="off"
    v-on="inputListeners"
  />
</template>

<script>
export default {
  name: 'EInput',

  props: {
    type: {
      type: String,
      default: 'text',
    },
    value: {
      type: [Number, String],
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
  },

  computed: {
    inputListeners: function() {
      return {
        ...this.$listeners,
        input: (event) => {
          this.$emit('input', event.target.value);
        },
      };
    },
  },
};
</script>
