<template>
  <div id="app" class="easy-start-new-page">
    <menuNavBar :isShowBtn="getDataTemplate.isShowBtn" @copy="copy()" v-if="!isControlsDisabled" />

    <div ref="esPage" class="easy-start-new-page-container">
      <VueSlickCarousel
        ref="carouselNavGroup"
        class="slider-nav-group"
        v-bind="settingsNavGroup"
        @beforeChange="setSlideCategoryGroup"
        v-if="!isControlsDisabled"
      >
        <div v-for="name in getDataKeys" :key="name" class="slider-nav-category-padding">
          <div v-if="!getGroupsRestricted || getGroupsRestricted.includes(name)" class="slider-nav-category-group">
            <span class="slider-nav-category-group-title">{{ $t(`easyStart.group.${name}.title`) }}</span>
          </div>
        </div>
      </VueSlickCarousel>

      <VueSlickCarousel
        ref="carouselNav"
        class="slider-nav"
        v-bind="settingsNav"
        @beforeChange="setSlideCategoryName"
        v-if="!isControlsDisabled"
        :key="'slider-nav-data-' + categoryGroup"
      >
        <div v-for="name in getDataGroupKeys" :key="name" class="p-t-24 p-h-8 p-b-36">
          <div
            class="slider-nav-category"
            :style="`background-image: url('/static/images/v3/slider-top-bg/slider-${name}.webp')`"
          >
            <span class="slider-nav-category-title">{{ $t(`easyStart.${name}.title`) }}</span>
          </div>
        </div>
      </VueSlickCarousel>

      <div class="slider-content" :class="{ 'top-spaced': isControlsDisabled }" v-if="getDataTemplate">
        <div class="components-box">
          <div class="components-mobile-info">
            <div v-if="categoryHaveMoreThanOneTemplate" class="template-number">
              {{ $t('v3common.template') }} {{ templateId + 1 }}
            </div>
            <div class="details-title">{{ $t(getDataTemplate.title) }}</div>
            <div class="details-desc">{{ $t(getDataTemplate.description) }}</div>
          </div>
          <ul class="components-list">
            <li v-for="name in getDataTemplate.componentList" :key="name">
              {{ $t(name) }}
            </li>
          </ul>
        </div>

        <div @mouseover="mouseOver" @mouseleave="mouseLeave" class="preview-slider-box">
          <div class="preview-image">
            <img :key="categoryName" :src="getDataTemplate.previewUrl" :alt="`Preview ${$t(getDataTemplate.title)}`" />
            <preview-slider
              v-if="categoryHaveMoreThanOneTemplate && !isControlsDisabled"
              :length-array-category-template="arrayTemplateLength"
              :index-category-template="templateId"
              @indexSlide="setIndexSlideTemplate"
            ></preview-slider>
          </div>
        </div>

        <div class="details-box">
          <div>
            <div v-if="categoryHaveMoreThanOneTemplate" class="template-number">
              {{ $t('v3common.template') }} {{ templateId + 1 }}
            </div>
            <div class="details-title">{{ $t(getDataTemplate.title) }}</div>
            <div class="details-desc">{{ $t(getDataTemplate.description) }}</div>
          </div>

          <div class="btns m-b-24">
            <div :disabled="!getDataTemplate.isShowBtn" class="btn btn-big btn-success-animate" @click="copy()">
              {{ getNameBtnBuild }}
            </div>
            <div class="btn btn-image" @click="goToUrl(getDataTemplate.googlePlayUrl)">
              <img src="/static/images/v3/google-play.svg" alt="GoogleApp" />
            </div>
          </div>

          <!-- <div v-if="getDataTemplate.detailsList" class="row item-details">
            <div
              v-for="(item, index) in getDataTemplate.detailsList"
              :key="`item-details-${index}`"
              class="item-details-col"
            >
              <div class="item-details-list">
                <div class="item-details-icon" v-html="item.icon"></div>
                <div class="item-details-title">{{ $t(item.title) }}</div>
                <div class="item-details-doc">{{ $t(item.doc) }}</div>
              </div>
            </div>
          </div> -->

          <div v-if="getDataTemplate.qrCodeImg" class="qr-code">
            <img
              :src="getDataTemplate.qrCodeImg"
              :alt="`Google Play App - ${getDataTemplate.title}`"
              class="p-b-4"
              width="108"
              height="108"
            />
            {{ $t('easyStart.try-app-now') }}
            <i class="h4 fab fa-android m-l-4 m-b-0 btn-group"></i>
          </div>
        </div>
      </div>
    </div>

    <e-modal name="modalCopyApp" :click-to-close="true" width="450">
      <template #header>{{ $t('v3modal.your-app-is-copying-now') }}</template>
      <template #content>
        <div class="text-center space-32">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">{{ $t('v3common.loading') }}...</span>
          </div>
        </div>
      </template>
    </e-modal>
  </div>
</template>

<style lang="scss" scoped>
.slider-nav-group::v-deep .slick-list {
  overflow: visible;
}
</style>

<style lang="scss">
html,
body,
#app {
  height: 100%;
  overflow: hidden;
}

#app {
  display: flex;
  flex-direction: column;
}

[lang='ar'] {
  .easy-start-new-page-container .slider-nav-category-title {
    font-size: 18px;
    line-height: 1;
  }
}

@media screen and (min-width: 992px) {
  [lang='ar'] {
    .list-lang {
      transform: translate(-70%, 0);
    }
  }
}

@media screen and (max-width: 768px) {
  .intercom-lightweight-app-launcher {
    left: 4px !important;
    right: auto;
    bottom: 4px !important;
  }
}
@media screen and (max-width: 500px) {
  .intercom-lightweight-app-launcher {
    left: -8px !important;
    bottom: -8px !important;
  }
}

.slider-nav-group {
  width: 100%;
  left: -20%;
  display: flex;
}

.slider-nav-category-padding {
  padding-top: 24px;
  padding-left: 8px;
  padding-right: 8px;
}

@media screen and (max-width: 370px) {
  .slider-nav-category-padding {
    padding: 0;
  }
}

@media screen and (max-width: 500px) {
  .slider-nav-group {
    width: 100%;
  }
}
</style>

<script>
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import VueSlickCarousel from 'vue-slick-carousel';
import projectTemplatesV3 from 'common/projectTemplatesV3';
import menuNavBar from './components/menuNavBar';
import { parseUrl } from './helpers';
import previewSlider from './components/previewSlider';

const HOTJAR_START_RECORD_FLAG = '?ZWNvbW1lcmNl';

export default {
  name: 'App',

  components: { menuNavBar, VueSlickCarousel, previewSlider },

  data: () => ({
    categoryGroup: '',
    categoryName: '',
    templateId: 0,

    isControlsDisabled: false,
    dontChangeTemplateId: false,

    appData: {},

    settingsNav: {
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      dots: false,
      arrows: false,
      focusOnSelect: true,
      centerMode: true,
      variableWidth: true,
      waitForAnimate: false,
      autoplay: true,
      autoplaySpeed: 6500,
    },

    settingsNavGroup: {
      infinite: false,
      variableWidth: true,
      focusOnSelect: true,
      centerMode: true,
      dots: false,
      arrows: false,
      dontAnimate: true,
    },
  }),

  computed: {
    getAppData() {
      return this.appData;
    },

    getDataKeys() {
      return Object.keys(this.appData);
    },

    getDataGroupKeys() {
      return Object.keys(this.appData[this.categoryGroup]);
    },

    getDataGroup() {
      return this.appData[this.categoryGroup];
    },

    getDataCategory() {
      return this.appData[this.categoryGroup][this.categoryName];
    },

    getDataTemplate() {
      return this.appData[this.categoryGroup][this.categoryName][this.templateId];
    },

    categoryHaveMoreThanOneTemplate() {
      return this.getDataCategory.length > 1;
    },

    arrayTemplateLength() {
      return Object.keys(this.getDataCategory).length || 0;
    },

    getNameBtnBuild() {
      return this.getDataTemplate.isShowBtn
        ? this.$t('easyStart.btn.build-this-app')
        : this.$t('easyStart.btn.coming-soon');
    },

    getGroupsRestricted() {
      return new URLSearchParams(window.location.href.split('?')?.[1]).get('groups');
    },
  },

  created() {
    this.setupAppData();
    this.setUrl(...parseUrl());
    this.setViewport();
  },

  mounted() {
    if (window.self != window.top) {
      const style = document.createElement('style');
      style.innerHTML = `
        .intercom-lightweight-app-launcher {
          display: none;
        }
        .navbar {
          display: none !important;
        }
      `;
      this.$el.appendChild(style);
    }

    this.firstSlideGroup(this.getIndexOfObjectField(this.getAppData, this.categoryGroup));
    this.firstSlide(this.getIndexOfObjectField(this.getDataGroup, this.categoryName));
    this.urlReset();
  },

  methods: {
    setUrl(categoryGroup, categoryName, templateId, noControls) {
      const noControlsUrlPart = 'no-controls';
      let url = '#/';

      const baseCategoryGroup = Object.keys(this.appData)[0];
      if (!(categoryGroup && this.appData[categoryGroup])) {
        // eslint-disable-next-line no-param-reassign
        categoryGroup = baseCategoryGroup;
      }
      url += `${categoryGroup}/`;
      this.categoryGroup = categoryGroup;

      const baseCategoryName = Object.keys(this.appData[categoryGroup])[0];
      if (!(categoryName && this.appData[categoryGroup][categoryName])) {
        // eslint-disable-next-line no-param-reassign
        categoryName = baseCategoryName;
      }
      url += `${categoryName}/`;
      this.categoryName = categoryName;

      const baseTemplateId = 0;
      if (!(templateId && this.appData[categoryGroup][categoryName][templateId])) {
        // eslint-disable-next-line no-param-reassign
        templateId = baseTemplateId;
      }
      url += `${templateId}/`;
      this.templateId = templateId;

      if (noControls) {
        url += noControlsUrlPart;
        this.isControlsDisabled = noControls;
      }

      // save query
      const queryParams = new URLSearchParams(window.location.href.split('?')?.[1]);

      if (queryParams.has('groups')) {
        url += `?groups=${queryParams.get('groups')}`;
      }

      window.location.href = window.location.origin + window.location.pathname + url;
    },

    setupAppData() {
      this.appData.all = {};

      for (const categoryGroup in projectTemplatesV3) {
        Object.assign(this.appData.all, projectTemplatesV3[categoryGroup]);
        this.appData[categoryGroup] = projectTemplatesV3[categoryGroup];
      }
    },

    goToUrl(url) {
      window.location.href = url;
    },

    firstSlideGroup(elem) {
      if (this.$refs.carouselNavGroup) {
        this.$refs.carouselNavGroup.goTo(elem);
      }
    },

    firstSlide(elem) {
      if (this.$refs.carouselNav) {
        if (elem != 0) {
          this.dontChangeTemplateId = true;
        }

        this.$refs.carouselNav.goTo(elem);
      }
    },

    setSlideCategoryGroup(oldSlideIndex, newSlideIndex) {
      this.categoryGroup = this.getDataKeys[newSlideIndex];
      this.categoryName = Object.keys(this.getDataGroup)[0];
      this.urlReset();
    },

    setSlideCategoryName(oldSlideIndex, newSlideIndex) {
      this.categoryName = this.getDataGroupKeys[newSlideIndex];
      if (this.dontChangeTemplateId) {
        this.dontChangeTemplateId = false;
      } else {
        // for not to lose templateId first slide (on custom link page load)
        this.templateId = 0;
      }
      this.urlReset();
    },

    urlReset() {
      this.setUrl(this.categoryGroup, this.categoryName, this.templateId, this.isControlsDisabled);
      this.rewriteVisual();
    },

    setIndexSlideTemplate(newSlideIndexTemplate) {
      this.templateId = newSlideIndexTemplate || 0;
      this.urlReset();
    },

    rewriteVisual() {
      this.applyCss();
    },

    getItemTitleCategory(item) {
      if (this.categoryHaveMoreThanOneTemplate) {
        return item[this.templateId]?.title;
      }
      return item?.title;
    },

    setViewport() {
      const meta = document.createElement('meta');
      meta.name = 'viewport';
      meta.setAttribute('content', 'width=device-width');
      document.getElementsByTagName('head')[0].appendChild(meta);
    },

    applyCss() {
      const cssRule = `
        .easy-start-new-page-container,
        .main-menu-link.purple {
          background-color: ${this.getDataTemplate.backgroundColor} !important;
        }
        .easy-start-new-page-container:before {
          background-image: url('${this.getDataTemplate.backgroundUrl}');
        }
        .easy-start-new-page .components-list li,
        .easy-start-new-page-container,
        .main-menu-link.purple {
          color: ${this.getDataTemplate.color};
        }
        .easy-start-new-page .details-box .item-details-list {
          color: ${this.getDataTemplate.color};
          fill: ${this.getDataTemplate.color};
          border-color: ${this.getDataTemplate.color};
        }
        .easy-start-new-page .details-box .item-details-list:before,
        .easy-start-new-page .details-box .item-details-list:after {
          border-left-color: ${this.getDataTemplate.color};
        }
      `;

      if (this.styleElement) {
        this.styleElement.remove();
        this.styleElement = null;
      }

      const style = document.createElement('style');
      style.type = 'text/css';
      style.innerHTML = cssRule;
      this.$el.appendChild(style);
      this.styleElement = style;
    },

    mouseOver() {
      if (this.$refs.carouselNav) {
        this.$refs.carouselNav.pause();
      }
    },

    mouseLeave() {
      if (this.$refs.carouselNav) {
        this.$refs.carouselNav.play();
      }
    },

    async copy() {
      if (!this.getDataTemplate.isShowBtn) return;

      this.$emit('stopScroll');

      this.mouseOver();

      this.$modal.show('modalCopyApp');

      const templateId = this.getDataTemplate.layoutsId;

      let templateCategoryGroup = 'content';
      for (const categoryGroup in projectTemplatesV3) {
        if (projectTemplatesV3[categoryGroup][this.categoryName]) {
          templateCategoryGroup = categoryGroup;
          break;
        }
      }

      const body = {
        templateId,
        name: this.$t(this.getDataTemplate.title),
        categoryGroup: templateCategoryGroup,
        userType: this.userType,
      };

      let UrlSite = '';
      if (window.location.origin.includes('easystart.')) {
        UrlSite = window.location.origin; //.replace('easystart.', '');
      }

      const result = await this.$httpMethod({
        url: `${UrlSite}/easy-start/copy/`,
        method: 'POST',
        body,
        requireSuccess: true,
        timeout: 5 * 60 * 1000, //5 minutes for copy files
        customErrors: {
          104: this.$t('v3notify.you-cannot-create-more-project'),
        },
      });

      if (!result) {
        setTimeout(() => {
          this.$modal.hide('modalCopyApp');
        }, 1000);

        return;
      }

      await dataLayer.push({ 'event': 'new_project' });

      this.$modal.hide('modalCopyApp');

      if (UrlSite) {
        UrlSite = UrlSite.replace('easystart.', '');
        window.top.location.href = `${UrlSite}/#/projectV3/${result.body.projectId}${
          this.getAppData?.ecommerce[this.categoryName]?.length
            ? HOTJAR_START_RECORD_FLAG + '=true&showEcommerceCallPopUp=true'
            : ''
        }`;
        return;
      }

      window.location.href = `/#/projectV3/${result.body.projectId}${
        this.getAppData?.ecommerce[this.categoryName]?.length
          ? HOTJAR_START_RECORD_FLAG + '=true&showEcommerceCallPopUp=true'
          : ''
      }`;
    },

    getIndexOfObjectField(object, value) {
      return Object.keys(object).findIndex((item) => item == value);
    },
  },
};
</script>
