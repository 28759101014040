<template>
  <VueSlickCarousel
    ref="carouselTemplate"
    class="preview-slider"
    v-bind="settingsTemplate"
    @beforeChange="setIndexTemplate"
  >
    <template #prevArrow>
      <div>
        <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.83862 2.25195L2.26267 9.16737L7.83862 16.0828"
            stroke-width="2.64934"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </template>
    <div v-for="key in lengthArrayCategoryTemplate" :key="key" class="slider-template-wrap">
      <div class="slider-template">
        {{ $t('v3common.template').toUpperCase() }}
        <div class="count-slider">
          {{ key }}
          <span class="count-slider-length">/ {{ lengthArrayCategoryTemplate }}</span>
        </div>
      </div>
    </div>
    <template #nextArrow>
      <div>
        <svg width="9" height="18" viewBox="0 0 9 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1.64722 2.25195L7.22317 9.16737L1.64722 16.0828"
            stroke-width="2.64934"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </template>
  </VueSlickCarousel>
</template>

<script>
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import VueSlickCarousel from 'vue-slick-carousel';

export default {
  name: 'previewSlider',

  components: {
    VueSlickCarousel,
  },

  props: ['lengthArrayCategoryTemplate', 'indexCategoryTemplate'],

  data: () => ({
    settingsTemplate: {
      dots: false,
      arrows: true,
      dontAnimate: true,
    },

    indexTemplate: 0,
  }),

  watch: {
    indexCategoryTemplate(newIndex) {
      this.showToSlide(newIndex);
    },
  },

  mounted() {
    this.showToSlide(this.indexCategoryTemplate);
  },

  methods: {
    showToSlide(elem) {
      this.$refs.carouselTemplate.goTo(elem);
    },

    setIndexTemplate(_oldSlideIndex, newSlideIndex) {
      this.indexTemplate = newSlideIndex;
      this.$emit('indexSlide', newSlideIndex);
    },
  },
};
</script>
