
function possiblyParseErrorCode(context, responseBody, customErrors = {}) {
  const errors = Object.assign(
    {
      101: 'Target not found',
      103: 'Server refused to perform request',
      104: 'Target already exist',
      105: 'Server error',
      106: 'Server-side validation error',
      107: 'Server error',
      111: 'Not enough storage quota for this action',
    },
    customErrors
  );

  if (responseBody.errorCode == 100) {
    // Auth failed
    document.location.href = '/';
    return;
  }

  if (responseBody.message) {
    context.$notify.error(responseBody.message);
    return;
  }

  if (errors[responseBody.errorCode]) {
    if (typeof errors[responseBody.errorCode] === 'function') {
      errors[responseBody.errorCode](responseBody);
    } else if (typeof errors[responseBody.errorCode] === 'string') {
      context.$notify.error(errors[responseBody.errorCode]);
    }

    return;
  }

  if (responseBody.errorCode == 112) {
    // NO_PERMISSIONS - no prePublisher (AN-1100) or suspicious activities
    return;
  }

  context.$notify.error('Did not execute request');
}

export default {
  install(Vue) {
    Vue.prototype.$httpMethod = async function (options = {}) {
      if (typeof options.timeout === 'undefined') {
        options.timeout = 30000;
      }

      if (Vue.$getShopifySessionToken) {
        const sessionToken = await Vue.$getShopifySessionToken();
        options.headers = {};
        options.headers['Authorization'] = `Ecommerce1 ${sessionToken}`;
      }

      return Vue.http(options)
        .catch((response) => response)
        .then((response) => {
          if (response.ok) {
            if (options.requireSuccess) {
              if (!response.body.success) {
                possiblyParseErrorCode(this, response.body, options.customErrors);
                return null;
              }
            }

            return response;
          }

          if (response.status === 0) {
            Vue.$notify.error('Server is unreachable');
          } else {
            Vue.$notify.error('Server error');
          }

          return null;
        });
    };

    Vue.$httpMethod = Vue.prototype.$httpMethod;
  },
};
