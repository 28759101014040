// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import './vue-notify';
import './polyfills';
import HTTPMethod from './HTTPMethod';
import PortalVue from 'portal-vue';
import UUID from 'vue-uuid';
import Vue from 'vue';
import VueAwesomeGrid from 'vue-awesome-grid';
import VueClipboard from 'vue-clipboard2';
import VueResource from 'vue-resource';
import VueTour from 'vue-tour';
import Vuelidate from 'vuelidate';
import numbro from 'numbro/dist/numbro.js';
import getErrorText from './publisher/views/v3/helpers/getErrorText';
import shopifySessionPlugin from './shopifySessionPlugin';

// to hide line 'You are running Vue in development mode...' in console
Vue.config.productionTip = false;

Vue.use(VueResource);
Vue.use(VueClipboard);
Vue.use(HTTPMethod);
Vue.use(VueAwesomeGrid);
Vue.use(VueTour);
Vue.use(PortalVue);
Vue.use(Vuelidate);
Vue.use(UUID);
Vue.use(shopifySessionPlugin);

const getNestedObject = (nestedObj, pathArr) =>
  pathArr.reduce((obj, key) => {
    if (obj && obj[key] !== 'undefined') {
      return obj[key];
    }

    return undefined;
  }, nestedObj);

function formatSize(size, defaultValue = 'n/a', withSizeCSSClass = null) {
  function getWithClass(title, addWithSizeClass) {
    if (!addWithSizeClass) {
      return title;
    }
    return `<span class="${addWithSizeClass}">${title}</span>`;
  }

  if (!size || isNaN(size)) {
    return defaultValue;
  }

  if (size > 1024 * 1024 * 1024 * 1024) {
    return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + ' ' + getWithClass('TB', withSizeCSSClass);
  } else if (size > 1024 * 1024 * 1024) {
    return (size / 1024 / 1024 / 1024).toFixed(2) + ' ' + getWithClass('GB', withSizeCSSClass);
  } else if (size > 1024 * 1024) {
    return (size / 1024 / 1024).toFixed(2) + ' ' + getWithClass('MB', withSizeCSSClass);
  } else if (size > 1024) {
    return (size / 1024).toFixed(2) + ' ' + getWithClass('KB', withSizeCSSClass);
  }
  return size.toString() + ' ' + getWithClass('B', withSizeCSSClass);
}

const EventBus = new Vue();
Vue.use({
  install: function (VueInstance) {
    Object.defineProperties(VueInstance.prototype, {
      // global events bus
      $bus: {
        get: function () {
          return EventBus;
        },
      },

      // uniqueId('some-id') method for non-repeatable html ids
      uniqueId: {
        enumerable: false,
        writable: true,
        value: function (idValue) {
          const prefix = idValue ? idValue + '-' : '';
          return prefix + this._uid;
        },
      },

      $formatCount: {
        enumerable: false,
        writable: true,
        value: function (value, defaultValue = 'n/a') {
          if (typeof value == 'undefined' || value === null || isNaN(value)) {
            return defaultValue;
          }

          return numbro(value).format('0,0');
        },
      },

      $formatSize: {
        enumerable: false,
        writable: true,
        value: (value, defaultValue = 'n/a', withSizeCSSClass = null) =>
          formatSize(value, defaultValue, withSizeCSSClass),
      },

      $formatCurrency: {
        enumerable: false,
        writable: true,
        value: function (value, defaultValue = 'n/a', skipCurrencySign = false) {
          if (typeof value == 'undefined' || value === null || isNaN(value)) {
            return defaultValue;
          }

          return (skipCurrencySign ? '' : '$') + numbro(value / 100).format('0,0.00');
        },
      },

      $formatCurrencyMilliCents: {
        enumerable: false,
        writable: true,
        value: function (value, defaultValue = 'n/a', moreMantissa = false) {
          if (typeof value == 'undefined' || value === null || isNaN(value)) {
            return defaultValue;
          }

          if (moreMantissa) {
            return '$' + numbro(value / 100 / 1000).format('0,0.0000');
          }

          return '$' + numbro(value / 100 / 1000).format('0,0.00');
        },
      },

      $formatPercent: {
        enumerable: false,
        writable: true,
        value: function (value, defaultValue = 'n/a') {
          if (typeof value == 'undefined' || value === null || isNaN(value)) {
            return defaultValue;
          }

          return numbro(value).format({
            average: true,
            mantissa: 1,
          });
        },
      },

      $formatFillRate: {
        enumerable: false,
        writable: true,
        value: function (row) {
          if (row.requestsCount <= 0) {
            return 'n/a';
          }

          const percent = ((row.impressionsCount / row.requestsCount) * 100).toFixed(1);
          return `${percent}<span class="percent">%</span>`;
        },
      },

      $classNameWithDisabled: {
        enumerable: false,
        writable: true,
        value: function (str) {
          return (value, row) => {
            const classNames = [];
            if (str) {
              classNames.push(str);
            }
            if (row.deletedAt) {
              classNames.push('disabled');
            }

            if (['waiting', 'inProgress'].includes(row.migrationStatus)) {
              classNames.push('disabled');
            }

            if (row.migrationStatus == 'failed') {
              classNames.push('bg-migration-failed');
            }

            if (row.isArchived) {
              classNames.push('bg-migration-archived');
            }

            return classNames.join(' ');
          };
        },
      },

      $invalid: {
        enumerable: false,
        writable: true,
        value: function (str) {
          if (!str) {
            return false;
          }
          const nested = getNestedObject(this, str.split('.'));
          return nested ? nested.$invalid : false;
        },
      },

      $getErrorText: {
        enumerable: false,
        writable: true,
        value: function (str) {
          return this.$t(getErrorText(str));
        },
      },
    });
  },
});

Vue.filter('formatSize', formatSize);
