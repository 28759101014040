const texts = {
  required: 'validation.mustBeSet',
  length: 'validation.must-be-2-255-characters-long',
  between: 'validation.must-be-beween-1000-and-9999',
  containsJavaWords: 'validation.cannot-contain-jawa-keywords',
  customPackageNameRegExp: 'validation.wrong-format',
  unitIdFormat: 'validation.format-must-be-ca-app-pub-xxxx',
  appIdFormat: 'validation.format-must-be-ca-app-pub-xxxx-yyyy',
  isPresentedInArchive: 'validation.must-be-presented-in-archive',
  isUrl: 'validation.must-be-valid-url',
  email: 'validation.must-be-valid-email',
  requiredOneSignalId: 'validation.onesignal-app-id-for-android-o',
  requiredYoutubeApiKey: 'validation.youtube-api-key-for-android-or',
  isFileExistsHead: 'validation.file-invalid-reupload',
};

const errors = {
  required: texts.required,
  minLength: texts.length,
  maxLength: texts.length,
  containsJavaWords: texts.containsJavaWords,
  customPackageNameRegExp: texts.customPackageNameRegExp,
  requiredIf: texts.required,
  unitIdFormat: texts.unitIdFormat,
  appIdFormat: texts.appIdFormat,
  isPresentedInArchive: texts.isPresentedInArchive,
  isUrl: texts.isUrl,
  email: texts.email,
  requiredOneSignalId: texts.requiredOneSignalId,
  requiredYoutubeApiKey: texts.requiredYoutubeApiKey,
};

export default (data) => {
  for (const key in data) {
    if (!data[key]) {
      return errors[key];
    }
  }
  return errors;
};
