<template>
  <modal
    v-inject
    :name="name"
    :classes="classes"
    :width="width"
    :height="height"
    :delay="delay"
    :adaptive="adaptive"
    :draggable="draggable"
    :scrollable="scrollable"
    :reset="reset"
    :click-to-close="clickToClose"
    :transition="transition"
    :overlay-transition="overlayTransition"
    :min-width="minWidth"
    :min-height="minHeight"
    :max-width="maxWidth"
    :max-height="maxHeight"
    :pivot-x="pivotX"
    :pivot-y="pivotY"
    :styles="styles"
    @before-close="$emit('before-close')"
    @before-open="beforeOpen"
    @opened="$emit('opened')"
  >
    <!-- @close-modal="`describe the method for closing the modal window`" -->
    <div class="v--modal-container">
      <button
        v-if="isShowCloseButton"
        type="button"
        class="v--modal-close-btn"
        title="Close"
        @click="$emit('close-modal')"
      ></button>

      <div v-if="$slots['header'] || $scopedSlots.header" class="v--modal-header" :class="headerClasses">
        <slot name="header"></slot>
      </div>

      <div v-if="$slots['content'] || $scopedSlots.content" class="v--modal-content" :class="contentClasses">
        <slot name="content"></slot>
      </div>

      <div v-if="$slots['footer'] || $scopedSlots.footer" class="v--modal-footer" :class="footerClasses">
        <slot name="footer"></slot>
      </div>

      <slot></slot>
    </div>
  </modal>
</template>

<script>
import inject from '@/publisher/views/v3/directives/inject';

export default {
  name: 'EModal',

  directives: { inject },

  props: {
    name: {
      type: String,
      required: true,
    },
    delay: {
      type: Number,
      default: 0,
    },
    adaptive: {
      type: Boolean,
      default: true,
    },
    draggable: {
      type: [Boolean, String],
      default: false,
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
    reset: {
      type: Boolean,
      default: false,
    },
    clickToClose: {
      type: Boolean,
      default: true,
    },
    transition: {
      type: String,
      default: '',
    },
    overlayTransition: {
      type: String,
      default: 'overlay-fade',
    },
    classes: {
      type: [String, Array],
      default: 'v--modal',
    },
    headerClasses: {
      type: [String, Array],
      default: '',
    },
    contentClasses: {
      type: [String, Array],
      default: '',
    },
    footerClasses: {
      type: [String, Array],
      default: '',
    },
    width: {
      type: [Number, String],
      default: 560,
    },
    height: {
      type: [Number, String],
      default: 'auto',
    },
    minWidth: {
      type: Number,
      default: 0,
    },
    minHeight: {
      type: Number,
      default: 0,
    },
    maxWidth: {
      type: Number,
      default: Infinity,
    },
    maxHeight: {
      type: Number,
      default: Infinity,
    },
    pivotX: {
      type: Number,
      default: 0.5,
    },
    pivotY: {
      type: Number,
      default: 0.5,
    },
    styles: {
      type: String,
      default: '',
    },
    isShowCloseButton: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    beforeOpen(event) {
      this.$emit('before-open', event);
    },
  },
};
</script>
