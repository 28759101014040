import shopifyCreateAppBridge from '@shopify/app-bridge';
import { getSessionToken } from '@shopify/app-bridge-utils';
require('../static/js/config');

export default {
  install(Vue) {
    const shopifyHost = new URLSearchParams(location.search).get('host');
    window.shopifyShop = new URLSearchParams(location.search).get('shop');

    if (shopifyHost && !(window.top == window.self)) {
      const shopifyAppBridge = shopifyCreateAppBridge({
        apiKey: window.SHOPIFY_API_KEY || '59f16160e2acec15d14b0bf40c90782c',
        host: shopifyHost,
        forceRedirect: true,
      });

      Vue.prototype.$getShopifySessionToken = async () => {
        const sessionToken = await getSessionToken(shopifyAppBridge);
        return sessionToken;
      };

      Vue.$getShopifySessionToken = Vue.prototype.$getShopifySessionToken;
    }
  },
};
